<template>
  <div class="poll-page">
    <div
      :class="`backdrop ${isMenuActive ? 'active' : ''}`"
      @click="toggleMenu(false)"
    ></div>
    <div class="more-polls" @click="toggleMenu(true)">More Polls</div>
    <div :class="`menu ${isMenuActive ? 'active' : ''}`">
      <div class="row">
        <p class="title">Poll Questions</p>
        <div class="close-btn" @click="toggleMenu(false)"></div>
      </div>
      <div v-if="isLoadingMorePolls" class="list">
        <LoadingComponent style="width: 4rem" :showText="true" />
      </div>
      <div v-else-if="pollDetail == 'not-found'" class="list">
        <div class="item">
          <div
            class="image dino"
            :style="{
              'background-image': `url('/dino-sad.png')`,
            }"
          ></div>
          <p class="title">No polls to show.</p>
        </div>
      </div>
      <div v-else class="list">
        <div
          class="item"
          v-for="poll in morePolls"
          :key="poll.id.stringValue"
          @click="openLink(`/polls?pollId=${poll.id.stringValue}`)"
        >
          <div
            class="image"
            :style="{
              'background-image': `url('${imageUrl}${poll.image.mapValue.fields.image.stringValue}?alt=media')`,
            }"
          ></div>
          <p class="title">{{ poll.content.stringValue }}</p>
          <!-- <p class="title">abc</p> -->
        </div>
      </div>
    </div>
    <div class="canvas">
      <div class="decor-1"></div>
      <div class="decor-2"></div>
      <div class="decor-3"></div>
      <div class="decor-4"></div>
      <div class="decor-5"></div>
    </div>
    <div class="top-bar">
      <div class="menu-btn" @click="toggleMenu(true)"></div>
      <div class="logo" @click="openLink('https://www.fampremium.com/')"></div>

      <div class="store-bar">
        <div
          class="item ios"
          @click="
            openLink(
              'https://apps.apple.com/us/app/fam-social-finance/id1587289940'
            )
          "
        ></div>
        <!-- <div class="item and"></div> -->
      </div>
    </div>
    <LoadingComponent v-if="isLoadingPoll" :showText="true" />
    <div v-else-if="pollDetail == 'not-found'" class="content">
      <div
        class="image"
        :style="{
          'background-image': `url('/dino-sad.png')`,
        }"
      ></div>
      <p class="title">Poll not found.</p>
    </div>
    <div v-else class="content">
      <div
        class="image"
        :style="{
          'background-image': `url('${imageUrl}${pollDetail.image.mapValue.fields.image.stringValue}?alt=media')`,
        }"
      ></div>
      <p class="title">
        {{ pollDetail.content.stringValue }}
      </p>
      <!-- <p class="text">notBuyCount: {{ voteCounts.notBuyCount }}</p> -->
      <!-- <p class="text">buyCount: {{ voteCounts.buyCount }}</p> -->
      <div class="row">
        <p class="price">
          $
          {{
            parseInt(
              pollDetail.price.stringValue.replace("$", "")
            ).toLocaleString()
          }}
        </p>
        <div class="view-detail" @click="openLink(pollDetail.url.stringValue)">
          View Details
        </div>
      </div>
      <p class="text">Do you wish to buy this?</p>
      <LoadingComponent v-if="isLoadingVote" />
      <div v-else-if="!(pollVoted != null)" class="btn-bar">
        <div class="poll-btn no" @click="votePoll(0)">
          <div class="icon"></div>
          <div class="label">No</div>
        </div>
        <div class="poll-btn yes" @click="votePoll(1)">
          <div class="icon"></div>
          <div class="label">Yes</div>
        </div>
      </div>
      <div v-else class="result-bar">
        <div
          :class="`item left ${pollVoted.voted == 0 ? 'selected' : ''}`"
          :style="`width: calc(20rem * ${pollResult.notBuy} / 100)`"
        >
          <span>{{ pollResult.notBuy }}%</span> No
        </div>
        <div
          :class="`item right ${pollVoted.voted == 1 ? 'selected' : ''}`"
          :style="`width: calc(20rem * ${pollResult.buy} / 100)`"
        >
          <span>{{ pollResult.buy }}%</span> Yes
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  doc,
  getDoc,
  increment,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import { auth, db } from "@/services/firebase";
import LoadingComponent from "@/components/LoadingComponent.vue";
import { IMAGE_URL, POLL_COOKIE_TOKEN } from "@/const";

export default {
  name: "PollPage",
  components: {
    LoadingComponent,
  },
  computed: {
    imageUrl() {
      return IMAGE_URL;
    },
    pollId() {
      return this.$route.query.pollId;
    },
    voteCounts() {
      return {
        buyCount: parseInt(
          this.pollDetail.buyCount?.integerValue ??
            this.pollDetail.buyCount?.doubleValue ??
            0
        ),
        notBuyCount: parseInt(
          this.pollDetail.notBuyCount?.integerValue ??
            this.pollDetail.notBuyCount?.doubleValue ??
            0
        ),
      };
    },
    pollResult() {
      const total = this.voteCounts.notBuyCount + this.voteCounts.buyCount;

      return {
        notBuy: Math.round((100 * this.voteCounts.notBuyCount) / total),
        buy: Math.round((100 * this.voteCounts.buyCount) / total),
      };
    },
  },
  data() {
    return {
      isLoadingPoll: true,
      isLoadingMorePolls: true,
      isLoadingVote: false,
      isLoggedin: false,
      pollDetail: {},
      morePolls: [],
      isMenuActive: false,
      pollVoted: null,
      pollRef: null,
    };
  },
  async created() {
    console.log("SIGN IN ANONYMOUSLY");
    // set pollVoted
    const cookie = this.$cookies.get(POLL_COOKIE_TOKEN);
    const prevCookie = Array.isArray(cookie) ? cookie : [];
    console.log("pollVoted", prevCookie.filter((e) => e.id == this.pollId)[0]);
    this.pollVoted = prevCookie.filter((e) => e.id == this.pollId)[0];
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
        this.isLoggedin = true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        // ...
      });
    await this.getPoll();
    console.log("after get poll");
    this.getMorePolls();
    this.isLoadingPoll = false;
  },
  methods: {
    async votePoll(vote) {
      this.isLoadingVote = true;
      console.log("db", db);
      const docRef = doc(db, "Poll", this.pollRef);
      if (vote == 1) {
        await updateDoc(docRef, {
          buyCount: increment(1),
        });
      } else {
        await updateDoc(docRef, {
          notBuyCount: increment(1),
        });
      }
      await this.getPoll();

      const newItem = { id: this.pollId, voted: vote };
      const cookie = this.$cookies.get(POLL_COOKIE_TOKEN);
      const prevCookie = Array.isArray(cookie) ? cookie : [];
      prevCookie.push(newItem);
      const now = new Date();
      const expiry = new Date(now.setMonth(now.getMonth() + 1));
      console.log("prevcookie", prevCookie);
      this.$cookies.set(POLL_COOKIE_TOKEN, JSON.stringify(prevCookie), expiry);
      this.pollVoted = { id: this.pollId, voted: vote };
      this.isLoadingVote = false;
    },
    async getPoll() {
      if (!this.pollId) {
        console.log("trigger not found");
        this.pollDetail = "not-found";
        return;
      }

      const q = query(collection(db, "Poll"), where("id", "==", this.pollId));
      const docsSnap = await getDocs(q);
      console.log(docsSnap);
      if (docsSnap.docs[0]) {
        console.log("trigger found");
        console.log(docsSnap.docs[0].id);
        this.pollRef = docsSnap.docs[0].id;
        this.pollDetail = docsSnap.docs[0]._document.data.value.mapValue.fields;
      } else {
        console.log("trigger not found");
        this.pollDetail = "not-found";
      }
    },
    async getMorePolls() {
      if (this.pollDetail == "not-found") {
        this.isLoadingMorePolls = false;
        return;
      }
      const groupId = this.pollDetail.groupId.stringValue;

      console.log("getmore polls", groupId);
      const q = query(collection(db, "Poll"), where("groupId", "==", groupId));
      const docsSnap = await getDocs(q);

      console.log(
        "docs snap",
        docsSnap.docs.map((p) => {
          return p._document.data.value.mapValue.fields;
        })
      );

      this.morePolls = docsSnap.docs.map((p) => {
        return p._document.data.value.mapValue.fields;
      });
      this.isLoadingMorePolls = false;
    },
    toggleMenu(state) {
      console.log("toggleMenu");
      this.isMenuActive = state;
    },
    openLink(link) {
      window.open(link);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables.scss";

.poll-page {
  width: 100vw;
  height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  --top-bar-height: 5rem;

  @media screen and (min-width: 768px) {
    --top-bar-height: 8rem;
  }

  .backdrop {
    width: 100vw;
    height: 100vh;

    background: rgba($color: #000000, $alpha: 0.3);

    position: fixed;
    top: 0;
    left: 0;

    z-index: -10;
    opacity: 0;
    transition: 0.35s ease-in-out;

    &.active {
      z-index: 10;
      opacity: 1;
    }
  }

  .more-polls {
    display: none;

    color: #6f6f6f;
    // font-size: 1.25rem;
    line-height: 2rem;

    position: fixed;
    top: var(--top-bar-height);
    left: 2rem;

    cursor: pointer;
    transition: 0.35s ease-in-out;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    &:hover {
      color: $black;

      &::before {
        max-width: 4rem;
      }
    }

    &::before {
      content: "";
      width: 100%;
      max-width: 2rem;
      height: 2px;

      background: linear-gradient(90deg, #44b2ff, #0053b3);
      border-radius: 1px;

      position: absolute;
      left: 0;
      bottom: 0;

      transition: 0.35s ease-in-out;
    }
  }

  .menu {
    width: 100vw;
    height: 100vh;

    background-color: #fff;

    position: fixed;
    top: 0;
    left: -100vw;

    transition: 0.5s ease-in-out;
    z-index: 100;
    padding: 2rem;

    @media screen and (min-width: 768px) {
      width: 30rem;
    }

    &.active {
      transform: translateX(100vw);

      @media screen and (min-width: 768px) {
        transform: translateX(20rem);
        left: -20rem;
      }

      .backdrop {
        display: flex;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 2rem;

      .title {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2rem;
      }

      .close-btn {
        width: 2rem;
        height: 2rem;

        // background: #eee;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/icons/cross-black.svg");

        transition: 0.35s ease-in-out;
        cursor: pointer;

        &:hover {
          filter: brightness(150%);
        }
      }
    }

    .list {
      height: calc(100vh - 8rem);

      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      overflow-x: hidden;
      overflow-y: auto;

      .item {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        cursor: pointer;
        transition: 0.35s ease-in-out;

        &:hover {
          // background-color: #f6f6f6;
        }

        & + .item {
          margin-top: 2rem;
        }

        .image {
          width: 5rem;
          height: 4rem;

          border: 1px solid #f2f2f2;
          border-radius: 0.2rem;
          background-size: cover;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;

          margin-right: 1rem;

          &.dino {
            border: unset;
            background-size: contain;
          }
        }

        .title {
          flex-grow: 1;
          font-weight: 400;
          line-height: 1.5rem;
          height: 3rem;
          max-width: calc(100% - 6rem);

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .canvas {
    .decor-1 {
      display: none;
      width: 20rem;
      height: 20rem;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/decors/decor-1.svg");

      position: absolute;
      top: -9rem;
      right: -9rem;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .decor-2 {
      display: none;
      width: 6.5rem;
      height: 80%;

      background-size: contain;
      background-position: center right;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/decors/decor-2.svg");

      position: absolute;
      bottom: 0;
      right: 0;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .decor-3 {
      display: none;
      width: 70vw;
      height: 100vh;

      // background-color: pink;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/decors/decor-3.svg");

      position: absolute;
      top: 0;
      left: 15vw;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .decor-4 {
      display: none;
      width: 16rem;
      height: 16rem;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/decors/decor-4.svg");

      position: absolute;
      bottom: -7rem;
      left: -7rem;

      @media screen and (min-width: 768px) {
        display: block;
      }
    }

    .decor-5 {
      display: block;
      width: 90vw;
      height: 40vh;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/decors/decor-5.svg");

      position: absolute;
      top: 10vh;
      left: 5vw;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  .top-bar {
    width: 100%;
    height: var(--top-bar-height);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;

    padding: 1rem;

    @media screen and (min-width: 768px) {
      height: var(--top-bar-height);
      justify-content: space-between;

      padding: 2rem;
    }

    .menu-btn {
      width: 2rem;
      height: 2rem;

      background-image: url("~@/assets/images/icons/menu.svg");
      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      border: 1px solid #f2f2f2;
      border-radius: 0.2rem;

      position: absolute;
      top: 1.5rem;
      left: 1.5rem;

      padding: 0.2rem;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .logo {
      width: 10rem;
      height: 100%;

      // background-color: #eee;
      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/logo.png");
      image-rendering: -webkit-optimize-contrast;

      cursor: pointer;

      @media screen and (min-width: 768px) {
        background-position: center left;
      }
    }

    .store-bar {
      display: none;
      flex-direction: row;

      @media screen and (min-width: 768px) {
        display: flex;
      }

      .item {
        height: 3rem;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-color: unset;

        position: relative;

        cursor: pointer;
        margin-right: 2rem;

        & + .item {
          margin-left: 2rem;
        }

        &::before {
          content: "";
          width: 90%;
          height: 90%;

          position: absolute;
          top: 5%;
          left: 5%;

          box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.25);
        }

        &.ios {
          width: 8.5rem;
          background-image: url("~@/assets/images/app-store.svg");
        }

        &.and {
          width: 10rem;
          background-image: url("~@/assets/images/play-store.svg");
        }
      }
    }
  }

  .content {
    width: calc(100vw - 3rem);
    height: 100vh;

    // background-color: red;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-top: var(--top-bar-height);
    padding-bottom: 3rem;

    @media screen and (min-width: 768px) {
      width: 60vw;
      padding-bottom: 4rem;
    }

    .image {
      width: 100%;
      aspect-ratio: 1.2;

      // background-color: #eee;
      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;

      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        flex-grow: 1;
        aspect-ratio: unset;
      }
    }

    .title {
      width: 100%;

      text-align: center;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 2rem;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin: 2rem 0;

      @media screen and (min-width: 768px) {
        margin-top: 1.5rem;
      }

      .price {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2rem;
        color: $dark-blue;

        position: relative;
        padding-right: 1rem;
      }

      .view-detail {
        font-size: 1rem;
        border-left: 1px solid #bfbfbf;
        line-height: 1.5rem;

        position: relative;
        padding-left: 1rem;
        padding-right: 1.75rem;

        cursor: pointer;
        transition: 0.35s ease-in-out;

        &:hover {
          color: #666;
        }

        &::before {
          content: "";

          width: 1.5rem;
          height: 1.5rem;

          background-size: contain;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;
          // background-color: #eee;
          background-image: url("~@/assets/images/icons/chevron-right.svg");

          padding: 0.15rem;

          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    .text {
      width: 100%;

      text-align: center;
      font-size: 1.25rem;
      line-height: 2rem;

      margin-bottom: 2rem;
    }

    .btn-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .poll-btn {
        line-height: 4rem;
        font-size: 1.25rem;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background-color: #eee;
        border-radius: 0.5rem;

        padding: 0 1.5rem;
        transition: 0.35s ease-in-out;
        cursor: pointer;

        &:hover {
          filter: brightness(120%);
        }

        & + .poll-btn {
          margin-left: 2rem;
        }

        &.no {
          background-color: rgba($color: $no, $alpha: 0.1);

          .icon {
            background-image: url("~@/assets/images/icons/no.svg");
          }
        }

        &.yes {
          background-color: rgba($color: $yes, $alpha: 0.1);

          .icon {
            background-image: url("~@/assets/images/icons/yes.svg");
          }
        }

        .icon {
          width: 2rem;
          height: 2rem;

          background-size: contain;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;

          margin-right: 1.5rem;
        }
      }
    }

    @keyframes resultAnim {
      0% {
        max-width: 0;
      }

      100% {
        max-width: 25rem;
      }
    }

    .result-bar {
      max-width: 25rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      border-radius: 0.5rem;
      overflow: hidden;
      animation: resultAnim 0.35s ease-in-out;

      .item {
        line-height: 4rem;
        min-width: 5rem;

        text-align: center;
        font-weight: 400;
        color: #6f6f6f;
        background-color: #f6f6f6;

        &.selected {
          background-color: #06d6a0;
          color: #fff;

          span {
            color: #fff;
          }
        }

        // &.left {
        //   border-top-left-radius: 0.5rem;
        //   border-bottom-left-radius: 0.5rem;
        // }

        // &.right {
        //   border-top-right-radius: 0.5rem;
        //   border-bottom-right-radius: 0.5rem;
        // }

        span {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
