const SET_IS_MENU_ACTIVE = 'setIsMenuActive';
const SET_IS_REGISTRATION_MODAL_ACTIVE = 'setIsRegistrationModalActive';

const appStore = {
  namespaced: true,
  state: {
    isMenuActive: false,
    isRegistrationModalActive: false
  },
  mutations: {
    [SET_IS_MENU_ACTIVE](state, payload) {
      state.isMenuActive = payload
    },
    [SET_IS_REGISTRATION_MODAL_ACTIVE](state, payload) {
      state.isRegistrationModalActive = payload
    }
  },
  actions: {
    setIsMenuActive(context, payload) {
      context.commit(SET_IS_MENU_ACTIVE, payload)
    },
    setIsRegistrationModalActive(context, payload) {
      context.commit(SET_IS_REGISTRATION_MODAL_ACTIVE, payload)
    }
  },
  
  modules: {
  }
}

export default appStore
