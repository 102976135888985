<template>
  <div class="loading-component">
    <div class="inner-circle"></div>
    <div class="outer-circle"></div>
    <p v-if="showText" class="text">Loading...</p>
  </div>
</template>


<script>
export default {
  name: "LoadingComponent",
  props: {
    showText: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables.scss";

.loading-component {
  min-width: 4rem;
  aspect-ratio: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  .inner-circle {
    width: 60%;
    aspect-ratio: 1;

    border-radius: 50%;
    border: 4px solid;
    border-color: $orange transparent $orange transparent;

    position: absolute;
    top: 20%;
    left: 20%;

    animation: spin 1.5s linear infinite reverse;
  }

  .outer-circle {
    width: 100%;
    aspect-ratio: 1;

    border-radius: 50%;
    border: 4px solid;

    border-color: transparent $dark-blue transparent $dark-blue;

    position: absolute;
    top: 0;
    left: 0;

    animation: spin 1s linear infinite;
  }

  .text {
    width: 100%;
    text-align: center;
    line-height: 1.5rem;

    position: absolute;
    left: 0;
    bottom: -2rem;
  }
}
</style>
