import PollPage from '@/pages/poll/PollPage.vue'

const pollRouter = [
  {
    path: '/polls',
    name: 'poll-page',
    component: PollPage
  },
]

export default pollRouter
