<template>
  <div v-if="!isRegistrationModalActive" class="footer-component ">
    <div class="ctn">
      <div class="logo"></div>
      <p class="copyright">Copyright © 2022 <span>FAM, Inc.</span></p>
      <div class="btn-bar">
        <div class="item fb" @click="openLink('https://web.facebook.com/profile.php?id=100063544361795')"></div>
        <!-- <div class="item twitter" @click="openLink('')"></div> -->
        <div class="item linkedin" @click="openLink('https://www.linkedin.com/company/fam-social-finance/')"></div>
        <div class="item ig" @click="openLink('https://www.instagram.com/fam_socialfinance/')"></div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "FooterComponent",
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
  computed: {
    isRegistrationModalActive() {
      return this.$store.state.appStore.isRegistrationModalActive;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables.scss";

.footer-component {
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .ctn {
    width: 100%;
    max-width: var(--max-content-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 2rem 0;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .logo {
      width: 6rem;
      height: 3rem;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/logo.png");

      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    .copyright {
      text-align: center;
      font-weight: 400;
      line-height: 2rem;
      color: #707070;

      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }

      span {
        font-weight: 400;
        color: $dark-blue;
      }
    }

    .btn-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .item {
        width: 2rem;
        height: 2rem;

        background-color: #f3f3f3;
        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        border-radius: 50%;

        padding: 0.4rem;
        transition: 0.35s ease-in-out;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: #f5f8ff;
        }

        & + .item {
          margin-left: 1rem;
        }

        &.linkedin {
          background-image: url("~@/assets/images/socials/linkedin.svg");

          &:hover,
          &:focus {
            background-image: url("~@/assets/images/socials/linkedin-hover.svg");
          }
        }

        &.fb {
          background-image: url("~@/assets/images/socials/fb.svg");

          &:hover,
          &:focus {
            background-image: url("~@/assets/images/socials/fb-hover.svg");
          }
        }

        &.ig {
          background-image: url("~@/assets/images/socials/ig.svg");

          &:hover,
          &:focus {
            background-image: url("~@/assets/images/socials/ig-hover.svg");
          }
        }

        &.twitter {
          background-image: url("~@/assets/images/socials/twitter.svg");

          &:hover,
          &:focus {
            background-image: url("~@/assets/images/socials/twitter-hover.svg");
          }
        }
      }
    }
  }
}
</style>
