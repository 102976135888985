<template>
  <div :class="`home-page ${isRegistrationModalActive ? 'fixed' : ''}`">
    <div class="dummy-image s1"></div>
    <div class="dummy-image s2"></div>
    <div class="dummy-image s3"></div>
    <div class="dummy-image s4"></div>
    <div class="dummy-image s5"></div>
    <div class="dummy-image s6"></div>
    <div class="dummy-image ds1"></div>
    <div class="dummy-image ds2"></div>
    <div class="dummy-image ds3"></div>
    <div class="dummy-image ds4"></div>
    <div class="dummy-image ds5"></div>
    <div class="dummy-image ds6"></div>
    <div @setRegistrationModal="setShowRegistrationModal"></div>

    <Transition>
      <div v-if="isRegistrationModalActive" class="registration-modal">
        <div class="backdrop"></div>
        <div class="ctn">
          <div class="close-btn" @click="setShowRegistrationModal(false)"></div>
          <div class="info">
            <p class="subtitle">The Secure Charge Card by
              FAM - Social Finance</p>
            <p class="title">Pre-register now!</p>
            <div class="list">
              <p class="item">Build credit with debit like functionality</p>
              <p class="item">Earn up to 4% annual rewards on your goal savings with
                no minimum deposit. </p>
              <p class="item">No annual fees, subscriptions, overdrafts, or interest
                to worry about. No previous credit is needed to qualify</p>
            </div>
          </div>
          <Transition>
            <div v-if="!submittedEmail" class="form">
              <div class="item">
                <div class="label">First Name</div>
                <input type="text" v-model="firstName">
                <p v-if="errors.firstName" class="error-text">{{ errors.firstName }}</p>
              </div>
              <div class="item">
                <div class="label">Last Name</div>
                <input type="text" v-model="lastName">
                <p v-if="errors.lastName" class="error-text">{{ errors.lastName }}</p>
              </div>
              <div class="item">
                <div class="label">Email</div>
                <input type="email" v-model="email" placeholder="you@website.com" @focusout="() => { }">
                <p v-if="errors.email" class="error-text">{{ errors.email }}</p>
              </div>

              <div :class="`btn submit ${isLoadingSubmission ? 'loading' : ''}`" @click="createEmailRegistration">Submit
              </div>
            </div>
            <div v-else class="completed-info">
              <div class="image"></div>
              <p class="text">
                Thank you for pre-registering for the FAM Banking Solution! Check your email for confirmation.
              </p>
              <div class="store-bar">
                <div class="item ios" @click="
                  openLink(
                    'https://apps.apple.com/us/app/fam-social-finance/id1587289940'
                  )
                "></div>
                <!-- <div class="item and"></div> -->
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>

    <div class="section main">
      <div class="canvas">
        <div class="decor-6"></div>
        <div class="web-image"></div>
      </div>
      <div class="ctn">
        <div class="content">
          <p class="title">
            Stop <span>Waiting.</span><br />
            Start <span>Saving</span> now <br />
            for a <span class="anim"></span>
          </p>
          <p class="description">
            FAM is an intuitive goal-driven platform that allows you to save
            with your friends and family. This is achieved with three different
            types of financial goals, allowing you to save for anything with
            anyone!
          </p>
          <p class="call-to-action">Join the early access waitlist now!</p>
          <div class="email">
            <input type="text" v-model="email" placeholder="Email address" size="1">
            <div class="btn join-now" @click="() => { setShowRegistrationModal(true) }">Join Waitlist</div>
          </div>
          <div class="store-bar">
            <div class="item ios" @click="
              openLink(
                'https://apps.apple.com/us/app/fam-social-finance/id1587289940'
              )
            "></div>
            <!-- <div class="item and"></div> -->
          </div>
          <div class="image"></div>
        </div>
        <div class="image-web"></div>
      </div>
    </div>

    <div class="section message">
      <div class="ctn">
        <p class="title">Mission Statement</p>
        <p class="description">
          Providing unmatched transparent consumer pricing and easy goal
          tracking ability for all
        </p>
      </div>
    </div>

    <div class="section video">
      <div class="canvas">
        <div class="decor-7"></div>
        <div class="decor-8"></div>
      </div>
      <div class="ctn">
        <div class="content">
          <div class="frame">
            <iframe src="https://www.youtube.com/embed/kHwIBc5PiQk?rel=0" frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
            <!-- <div class="play-btn"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div id="service-section" class="section service">
      <div class="section-title">
        <p class="title">Featured <span>Services</span> We Provide</p>
      </div>
      <div class="ctn">
        <div class="canvas">
          <div class="decor-8"></div>
        </div>
        <div class="content">
          <p class="title">
            Traditional social media meets powerful financial tools!
          </p>
          <p class="description">
            FAM is an intuitive goal-driven platform that allows you to save
            with your friends and family. This is achieved with three different
            types of financial goals, allowing you to save for anything with
            anyone!
          </p>

          <div class="list">
            <div class="item i1">
              <div class="icon"></div>
              <p class="text">
                Publish and adopt templates to make saving easier, select from
                preset templates or create your own to make sure you are saving
                for the right items.
              </p>
            </div>
            <div class="item i2">
              <div class="icon"></div>
              <p class="text">
                Set and save towards your wildest dreams, weather you are saving
                for a trip to Europe, or to pay this months rent: FAM has you
                covered.
              </p>
            </div>
            <div class="item i3">
              <div class="icon"></div>
              <p class="text">Financial integration (coming soon).</p>
            </div>
          </div>
        </div>
      </div>
      <div class="ctn">
        <div class="content reverse">
          <div class="canvas">
            <div class="decor-9"></div>
          </div>
          <p class="title">
            Streamline the saving process for you and your friends
          </p>
          <p class="description">
            We built FAM to break down the financial stigma around modern
            consumerism, and create an open space for all to discuss financial
            topics and learn best practices.
          </p>

          <div class="list">
            <div class="item i4">
              <div class="icon"></div>
              <p class="text">
                Browse and join FAM financial groups to learn from experts and
                like-minded individuals
              </p>
            </div>
            <div class="item i5">
              <div class="icon"></div>
              <p class="text">
                With FAM, you can interact with friend and family while saving
                for financial goals together
              </p>
            </div>
            <div class="item i6">
              <div class="icon"></div>
              <p class="text">
                Post polls to get real-time feedback before making a purchase or
                starting a goal
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="feature-section" class="section feature">
      <div class="section-title">
        <p class="title">Key <span>Features</span> of Our App</p>
      </div>
      <div class="ctn">
        <div class="content">
          <div class="item">
            <div class="image"></div>
            <p class="text">Financial Templates</p>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="text">Groups</p>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="text">Buy or Not Polls</p>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="text">Social Newsfeed</p>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="text">Social Profile</p>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="text">Goal Tracker</p>
          </div>
        </div>
        <div class="web-content">
          <div :class="`item i1 ${selectedFeature == 1 ? 'active' : ''}`" @click="selectFeature(1)">
            <div class="image"></div>
            <p class="text">Financial Templates</p>
          </div>
          <div :class="`item i2 ${selectedFeature == 2 ? 'active' : ''}`" @click="selectFeature(2)">
            <div class="image"></div>
            <p class="text">Groups</p>
          </div>
          <div :class="`item i3 ${selectedFeature == 3 ? 'active' : ''}`" @click="selectFeature(3)">
            <div class="image"></div>
            <p class="text">Buy or Not Polls</p>
          </div>
          <div :class="`item i4 ${selectedFeature == 4 ? 'active' : ''}`" @click="selectFeature(4)">
            <div class="image"></div>
            <p class="text">Social Newsfeed</p>
          </div>
          <div :class="`item i5 ${selectedFeature == 5 ? 'active' : ''}`" @click="selectFeature(5)">
            <div class="image"></div>
            <p class="text">Social Profile</p>
          </div>
          <div :class="`item i6 ${selectedFeature == 6 ? 'active' : ''}`" @click="selectFeature(6)">
            <div class="image"></div>
            <p class="text">Goal Tracker</p>
          </div>

          <div class="circle-ctn">
            <div class="circle c1"></div>
            <div class="circle c2"></div>
            <div class="circle c3"></div>
            <div class="circle c4"></div>
          </div>

          <div :class="`image i${selectedFeature}`"></div>

          <!-- <div :class="`image dummy i1`"></div>
          <div :class="`image dummy i2`"></div>
          <div :class="`image dummy i3`"></div>
          <div :class="`image dummy i4`"></div>
          <div :class="`image dummy i5`"></div>
          <div :class="`image dummy i6`"></div> -->
        </div>
      </div>
    </div>

    <div id="banking-section" class="section banking">
      <!--<div class="section-title">
        <p class="title">Featured <span>Services</span> We Provide</p>
      </div>-->
      <div class="ctn">
        <div class="canvas">
          <div class="decor-12"></div>
        </div>
        <div class="content">
          <p class="title">
            Banking Solution
          </p>
          <p class="description">
            Building credit shouldn’t be risky or confusing, and your money should earn more than 0.33% interest. FAM’s
            banking solution gives you access to our Secure Charge Card and high-reward account. Join the waitlist now to
            take
            the first step toward financial freedom!
          </p>

          <div class="list">
            <div class="item">
              <div class="bullet">1</div>
              <p class="text">
                Build credit with debit-like functionality. This means you don’t need to alter your current spending
                habits, or keep track of a credit limit, just do as you are but get rewarded
              </p>
            </div>
            <div class="item">
              <div class="bullet">2</div>
              <p class="text">
                Earn up to 4% annual rewards on your goal savings with
                no minimum deposit. That’s over 12 times the national average interest rate for savings accounts.
              </p>
            </div>
            <div class="item">
              <div class="bullet">3</div>
              <p class="text">No annual fees, subscriptions, overdrafts, or interest
                to worry about. No previous credit is needed to qualify</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="team-section" class="section team">
      <div class="canvas">
        <div class="decor-7"></div>
        <div class="decor-8"></div>
      </div>
      <div class="section-title">
        <p class="title">Meet the <span>Team</span></p>
      </div>
      <div class="content-ctn">
        <div class="content">
          <div class="item">
            <div class="image"></div>
            <p class="name">Louis Cirignano</p>
            <p class="position">CEO & Founder</p>
            <div class="linkedin" @click="openLink('https://www.linkedin.com/in/louis-r-cirignano')"></div>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="name">Khyteang</p>
            <p class="position">CTO</p>
            <div class="linkedin" @click="openLink('https://www.linkedin.com/in/khyteangl/')"></div>
          </div>

          <div class="item">
            <div class="image"></div>
            <p class="name">Alicia Gibney</p>
            <p class="position">Creative Media Director</p>
            <div class="linkedin" @click="
              openLink('https://www.linkedin.com/in/alicia-gibney-306579193')
            "></div>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="name">Tatiana Tompkins</p>
            <p class="position">Business Development Manager</p>
            <div class="linkedin" @click="openLink('https://www.linkedin.com/in/tatianatompkins0/')"></div>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="name">Hengjimmy Song</p>
            <p class="position">Software Engineer</p>
            <div class="linkedin" @click="openLink('https://www.linkedin.com/in/hengjimmy-song/')"></div>
          </div>
          <div class="item">
            <div class="image"></div>
            <p class="name">Brian Lahoue</p>
            <p class="position">Advisor</p>
            <div class="linkedin" @click="openLink('https://www.linkedin.com/in/brianlahoue')"></div>
          </div>
        </div>
      </div>
    </div>

    <div id="join-now-section" class="section download">
      <div class="canvas">
        <div class="decor-10"></div>
        <div class="decor-11"></div>
      </div>
      <p class="title">Download App</p>
      <div class="store-bar">
        <div class="item ios" @click="
          openLink(
            'https://apps.apple.com/us/app/fam-social-finance/id1587289940'
          )
        "></div>
        <!-- <div class="item and"></div> -->
      </div>
    </div>

    <Teleport to="title"> HomePage </Teleport>
  </div>
</template>


<script>
import { signInAnonymously } from "firebase/auth";
import { auth, db } from "@/services/firebase";
import { collection, addDoc } from "firebase/firestore";
export default {
  name: "HomePage",
  computed: {
    isRegistrationModalActive() {
      return this.$store.state.appStore.isRegistrationModalActive;
    }
  },
  data() {
    return {
      selectedFeature: 1,
      firstName: "",
      lastName: "",
      email: "",
      isLoadingSubmission: false,
      submittedEmail: false,
      errors: {
        firstName: "",
        lastName: "",
        email: ""
      }
    };
  },
  created() {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
        this.isLoggedin = true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        // ...
      });
  },
  methods: {
    selectFeature(id) {
      this.selectedFeature = id;
    },
    openLink(link) {
      window.open(link);
    },
    setShowRegistrationModal(param) {
      console.log("setShowRegistrationModal", param)
      this.$store.dispatch("appStore/setIsRegistrationModalActive", param);
    },
    async createEmailRegistration() {
      if (this.isLoadingSubmission) {
        return
      }
      let errors = {
        firstName: "",
        lastName: "",
        email: ""
      }
      if (!this.firstName) {
        errors = { ...errors, firstName: "First name can not be blank!" }
      }

      if (!this.lastName) {
        errors = { ...errors, lastName: "Last name can not be blank!" }
      }

      //eslint-disable-next-line 
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      if (!this.email) {
        errors = { ...errors, email: "Email can not be blank!" }
      } else if (!this.email.match(regex)) {
        errors = { ...errors, email: "Email is not valid!" }
      }



      this.errors = errors

      if (errors.firstName || errors.lastName || errors.email) {
        return
      }

      this.isLoadingSubmission = true;
      console.log("createa Email", this.email, this.firstName
        , this.lastName
      )

      await addDoc(collection(db, "EmailRegistration"), {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      });
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.submittedEmail = true;
      this.isLoadingSubmission = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables.scss";

.home-page {
  padding-top: var(--nav-bar-height);

  position: relative;
  // z-index: 10;

  &.fixed {
    position: fixed;
    z-index: 50;
  }

  .dummy-image {
    width: 1rem;
    height: 1rem;

    position: absolute;
    left: -100vw;
    top: 0;

    background-size: contain;
    background-position: center;
    background-origin: content-box;
    background-repeat: no-repeat;

    @for $i from 1 through 6 {
      &.s#{$i} {
        background-image: url("~@/assets/images/features/feature-#{$i}-white.svg");
      }

      &.ds#{$i} {
        background-image: url("~@/assets/images/features/feature-phone-#{$i}.png");
      }
    }
  }

  .registration-modal {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 10;
    // padding-top: var(--nav-bar-height);
    // transform: translateZ(10);
    // transform-style: preserve-3d;

    .backdrop {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;

      background-color: rgba($color: #000000, $alpha: 0.25);
    }

    .ctn {
      width: 100vw;
      height: unset;
      max-height: calc(100vh - 6rem);

      background-color: #fff;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      padding: 2rem;
      z-index: 10;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;

      @media screen and (min-width: 768px) {
        width: 80vw;
        height: unset;

        flex-direction: row;
        justify-content: space-between;

        overflow-y: hidden;
        padding-top: 4rem;
        padding: 5rem;
      }

      &>* {
        @media screen and (min-width: 768px) {
          width: 47%;
        }
      }


      .close-btn {
        width: 2rem;
        height: 2rem;

        // background-color: red;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/icons/cross-black.svg");

        transition: 0.35s ease-in-out;
        cursor: pointer;

        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        &:hover {
          filter: brightness(150%);
        }
      }

      .info {
        .subtitle {
          display: none;
          max-width: calc(100% - 2rem);
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.5rem;
          letter-spacing: .02em;

          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            display: flex;
          }
        }

        .title {
          max-width: calc(100% - 2rem);
          font-size: 1.75rem;
          font-weight: 600;
          line-height: 2.25rem;
          letter-spacing: .02em;

          margin-bottom: 1rem;
        }

        .list {
          margin-bottom: 2rem;

          .item {
            border-bottom: 1px solid #e5e5e5;

            padding: .5rem 0;
            padding-left: 3rem;

            position: relative;

            @media screen and (min-width: 768px) {
              padding: 1rem 0;
              padding-left: 3rem;
            }

            &::before {
              content: "";

              width: 1.5rem;
              height: 1.5rem;

              background-image: url("~@/assets/images/icons/blue-check.svg");
              background-size: contain;
              background-position: center;
              background-origin: content-box;
              background-repeat: no-repeat;

              position: absolute;
              top: .5rem;
              left: 1rem;

              @media screen and (min-width: 768px) {
                top: 1rem;

              }
            }
          }
        }
      }

      .form {
        width: 100%;

        background-color: #fff;
        box-shadow: 0 0 15px rgba($color: #111111, $alpha: 0.1);

        padding: 1.5rem;

        @media screen and (min-width: 768px) {
          width: 47%;
        }

        .item {
          margin-bottom: 1rem;

          .label {
            color: #111111;

            margin-bottom: 0.25rem;
          }

          input {
            width: 100%;
            border: 1px solid #f3f3f3;
            line-height: 2rem;
            border-radius: .25rem;

            padding: 0 .5rem;

            @media screen and (min-width: 768px) {
              line-height: 3rem;
            }


            &::placeholder {
              color: #b7b7b7
            }
          }

          .error-text {
            font-size: .75rem;
            font-weight: 400;
            letter-spacing: .02em;
            color: $no
          }
        }

        .submit {
          width: 100%;
          border-radius: .25rem;
          background-color: $dark-blue;
          color: #fff;
          line-height: 2rem;
          text-align: center;
          transition: .35s ease-in-out;

          @media screen and (min-width: 768px) {
            line-height: 3rem;
          }

          &:hover {
            filter: brightness(110%);
            opacity: 1;
          }

          &.loading {
            background-color: #888;
          }
        }
      }

      .completed-info {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: #fff;
        box-shadow: 0 0 15px rgba($color: #111111, $alpha: 0.1);

        padding: 1.5rem;

        @media screen and (min-width: 768px) {
          width: 47%;
        }

        .image {
          width: 6rem;
          height: 6rem;

          background-image: url("~@/assets/images/icons/blue-check.svg");
          background-size: contain;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;
        }

        .text {
          font-size: 1.25rem;
          letter-spacing: .02em;
          font-weight: 400;
          text-align: center;

          margin: 1rem 0;
        }

        .store-bar {
          width: 100%;

          display: flex;
          flex-direction: row;
          justify-content: center;

          .item {
            height: 3rem;

            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-color: unset;

            position: relative;

            cursor: pointer;
            // margin-right: 2rem;

            &+.item {
              margin-left: 2rem;
            }

            &::before {
              content: "";
              width: 90%;
              height: 90%;

              position: absolute;
              top: 5%;
              left: 5%;

              box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.25);
            }

            &.ios {
              width: 8.5rem;
              background-image: url("~@/assets/images/app-store.svg");
            }

            &.and {
              width: 10rem;
              background-image: url("~@/assets/images/play-store.svg");
            }
          }
        }
      }
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    padding-bottom: 3rem;

    &.one-page {
      height: calc(100vh - var(--nav-bar-height));
    }

    .section-title {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-top: 2rem;
      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 2rem;

        @media screen and (min-width: 768px) {
          font-size: 2.5rem;
          line-height: 3.5rem;
        }

        span {
          font-weight: 600;
          color: $dark-blue;
        }
      }
    }

    .ctn {
      width: 100%;
      max-width: var(--max-content-width);
    }
  }

  .main {
    @media screen and (min-width: 768px) {
      height: calc(100vh - var(--nav-bar-height));
    }

    .canvas {
      .decor-6 {
        display: none;
        width: 42vw;
        height: 42vw;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-6.svg");

        position: absolute;
        top: calc(var(--nav-bar-height) * -1);
        right: -1rem;
        // transform: translateZ(1px);

        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      @keyframes webImage {
        from {
          transform: translateX(120%);
        }

        to {
          transform: unset;
        }
      }

      .web-image {
        display: none;
        width: 50vw;
        height: 100%;

        // background-color: pink;
        background-size: contain;
        background-position: top right;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/home/home-1.png");
        image-rendering: -webkit-optimize-contrast;

        position: absolute;
        top: 0;
        right: 0rem;

        // transform: translateY(5%);
        // transform: translateZ(2px);
        animation: webImage 1.5s ease-in-out;

        @media screen and (min-width: 992px) {
          display: flex;
          width: 50vw;
        }
      }
    }

    .ctn {
      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 768px) {
          width: 100%;

          align-items: flex-start;
        }

        .title {
          font-weight: 400;
          line-height: 2rem;
          font-size: 1.5rem;
          text-align: center;

          margin-bottom: 1rem;
          margin-top: 2rem;

          @media screen and (min-width: 768px) {
            text-align: left;
            line-height: 5.5rem;
            font-size: 4.5rem;
            margin-top: 0;
          }

          span {
            font-weight: 600;
          }

          @keyframes textAnim {
            0% {
              content: "";
            }

            3% {
              content: "W";
            }

            6% {
              content: "We";
            }

            9% {
              content: "Wed";
            }

            12% {
              content: "Wedd";
            }

            15% {
              content: "Weddi";
            }

            18% {
              content: "Weddin";
            }

            21% {
              content: "Wedding";
            }

            24% {
              content: "";
            }

            27% {
              content: "V";
            }

            30% {
              content: "Va";
            }

            33% {
              content: "Vac";
            }

            36% {
              content: "Vaca";
            }

            39% {
              content: "Vacat";
            }

            42% {
              content: "Vacati";
            }

            45% {
              content: "Vacatio";
            }

            48% {
              content: "Vacation";
            }

            51% {
              content: "";
            }

            54% {
              content: "N";
            }

            57% {
              content: "Ne";
            }

            60% {
              content: "New";
            }

            63% {
              content: "New H";
            }

            66% {
              content: "New Ho";
            }

            69% {
              content: "New Hom";
            }

            72% {
              content: "New Home";
            }

            75% {
              content: "New C";
            }

            78% {
              content: "New Ca";
            }

            81% {
              content: "New Car";
            }

            84% {
              content: "New H";
            }

            87% {
              content: "New Ho";
            }

            90% {
              content: "New Hob";
            }

            93% {
              content: "New Hobb";
            }

            96% {
              content: "New Hobby";
            }

            100% {
              content: "";
            }
          }

          span.anim {
            color: $dark-blue;

            &:before {
              content: "";

              font-weight: 400;

              animation: textAnim 10s linear infinite;
            }
          }
        }

        .description {
          width: 100%;
          font-weight: 400;
          line-height: 1.5rem;
          letter-spacing: 0.01em;
          text-align: center;

          color: #707070;

          margin-bottom: 2rem;

          @media screen and (min-width: 768px) {
            width: 50%;
            text-align: left;
          }
        }

        .call-to-action {
          width: 100%;
          font-weight: 400;
          line-height: 1.5rem;
          font-size: 1rem;
          letter-spacing: 0.02em;
          text-align: center;

          color: $dark-blue;

          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            width: 50%;
            font-size: 1.25rem;
            text-align: left;
          }
        }

        .email {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          border: 1px solid #e6e6e6;
          border-radius: .5rem;

          padding: 0.25rem;
          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            width: 40%;
          }


          input {
            flex-grow: 1;
            line-height: 2.5rem;
            font-size: 1rem;
            border-radius: .25rem;

            padding: 0 .5rem;
            outline: none;
            border: unset;


            @media screen and (min-width: 768px) {
              line-height: 3rem;
            }


            &::placeholder {
              color: #b7b7b7
            }
          }

          .join-now {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
            background-color: $dark-blue;
            color: #fff;
            line-height: 2.5rem;
            text-align: center;

            padding: 0 1rem;
            transition: .35s ease-in-out;

            @media screen and (min-width: 768px) {
              // margin-left: -1rem;
              line-height: 3rem;
            }


            &:hover {
              filter: brightness(110%);
              opacity: 1;
            }

          }
        }

        .store-bar {
          display: flex;
          flex-direction: row;

          .item {
            height: 3rem;

            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-color: unset;

            position: relative;

            cursor: pointer;
            // margin-right: 2rem;

            &+.item {
              margin-left: 2rem;
            }

            &::before {
              content: "";
              width: 90%;
              height: 90%;

              position: absolute;
              top: 5%;
              left: 5%;

              box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.25);
            }

            &.ios {
              width: 8.5rem;
              background-image: url("~@/assets/images/app-store.svg");
            }

            &.and {
              width: 10rem;
              background-image: url("~@/assets/images/play-store.svg");
            }
          }
        }

        .image {
          width: 100%;
          height: 25rem;

          background-size: contain;
          background-position: bottom;
          background-origin: content-box;
          background-repeat: no-repeat;
          background-image: url("~@/assets/images/home/home-1.png");
          image-rendering: -webkit-optimize-contrast;

          // margin-top: 3rem;
          transform: translateX(-4%);

          @media screen and (min-width: 768px) {
            display: none;
          }
        }
      }
    }
  }

  .message {
    padding: 2rem 0;
    background-color: #f4fcff;

    .ctn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        color: $dark-blue;
        font-size: 1.25rem;
        font-weight: 600;
        letter-spacing: 0.01em;
        line-height: 2rem;
        text-align: center;

        margin-bottom: 0.5rem;
      }

      .description {
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .video {
    // padding: 4rem 0;
    padding: 0;

    .canvas {
      background-color: #f8f8f8;

      .decor-7 {
        width: 6rem;
        height: 6rem;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-7.svg");

        position: absolute;
        top: 2rem;
        right: 1rem;

        @media screen and (min-width: 768px) {
          width: 12rem;
          height: 12rem;

          top: 5vw;
          right: 5vw;
        }
      }

      .decor-8 {
        width: 6rem;
        height: 6rem;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-7.svg");

        position: absolute;
        bottom: 2rem;
        left: 1rem;

        @media screen and (min-width: 768px) {
          width: 12rem;
          height: 12rem;

          bottom: 5vw;
          left: 5vw;
        }
      }
    }

    .content {
      width: 100%;

      padding: 4rem 1rem;

      @media screen and (min-width: 768px) {
        padding: 10vw 6vw;
      }

      .frame {
        width: 100%;
        aspect-ratio: 1.77;

        border-radius: 1rem;

        background-color: #888;
        position: relative;
        overflow: hidden;

        iframe {
          width: 100%;
          height: 100%;

          .ytp-button {
            display: none !important;
          }
        }

        .play-btn {
          width: 4rem;
          height: 4rem;

          background-size: contain;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;
          background-image: url("~@/assets/images/play-icon.svg");

          position: absolute;
          top: calc(50% - 2rem);
          left: calc(50% - 2rem);

          @media screen and (min-width: 768px) {
            width: 6rem;
            height: 6rem;

            top: calc(50% - 3rem);
            left: calc(50% - 3rem);
          }
        }
      }
    }
  }

  .service {
    .ctn {
      width: var(--max-content-width);
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;

      position: relative;

      &+.ctn {
        margin-top: 3rem;
      }

      .canvas {
        .decor-8 {
          display: none;
          width: 50vw;
          height: 100vh;

          // background-color: black;
          background-size: contain;
          background-position: center right;
          background-origin: content-box;
          background-repeat: no-repeat;
          background-image: url("~@/assets/images/decors/decor-8.png");
          image-rendering: -webkit-optimize-contrast;

          position: absolute;
          top: -2rem;
          right: calc((100vw - var(--max-content-width)) / -2);

          // transform: translateX(10%);

          @media screen and (min-width: 768px) {
            display: block;
          }
        }

        .decor-9 {
          display: none;
          width: 50vw;
          height: 100vh;

          // background-color: black;
          background-size: contain;
          background-position: center left;
          background-origin: content-box;
          background-repeat: no-repeat;
          background-image: url("~@/assets/images/decors/decor-9.png");
          image-rendering: -webkit-optimize-contrast;

          position: absolute;
          bottom: -2rem;
          left: calc((100vw - var(--max-content-width)) / -2);

          transform: translateY(5%);

          @media screen and (min-width: 768px) {
            display: block;
          }
        }
      }

      .content {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 768px) {
          width: 50%;
          min-height: 90vh;
        }

        &.reverse {
          align-self: flex-end;
        }

        .title {
          line-height: 1.5rem;
          text-align: center;

          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            width: 100%;

            text-align: left;
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 2.5rem;
          }
        }

        .description {
          font-weight: 400;
          letter-spacing: 0.01em;
          line-height: 1.5rem;
          text-align: center;
          color: #292d32;

          margin-bottom: 1.5rem;

          @media screen and (min-width: 768px) {
            text-align: left;
          }
        }

        .item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            width: 80%;
            align-self: start;
          }

          @for $i from 1 through 6 {
            &.i#{$i} {
              .icon {
                &::before {
                  background-image: url("~@/assets/images/services/service-#{$i}.svg");
                }
              }
            }
          }

          .icon {
            width: 2.5rem;
            height: 2.5rem;

            background: linear-gradient(45deg, #ff852d, #ff5100);
            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;
            border-radius: 50%;
            box-shadow: 0 10px 30px rgba($color: $orange, $alpha: 0.15);

            margin-right: 1.5rem;
            position: relative;

            &::before {
              content: "";
              width: 1.4rem;
              height: 1.4rem;

              background-size: contain;
              background-position: center;
              background-origin: content-box;
              background-repeat: no-repeat;

              position: absolute;
              top: calc(50% - 0.7rem);
              left: calc(50% - 0.7rem);
            }
          }

          .text {
            flex-grow: 1;
            width: calc(100% - 4rem);
            color: #292d32;
            letter-spacing: 0.01em;
            font-size: 0.9rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .feature {
    background-color: #f4fcff;

    .ctn {
      .content {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 2rem;

        @media screen and (min-width: 768px) {
          display: none;
        }

        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
              .image {
                background-image: url("~@/assets/images/features/feature-#{$i}.svg");
              }
            }
          }

          .image {
            width: 4rem;
            height: 4rem;

            border-radius: 50%;
            border: 1px solid $orange;
            background-color: #fff;
            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;

            padding: 0.9rem;

            margin-bottom: 1rem;
          }

          .text {
            text-align: center;
          }
        }
      }

      .web-content {
        display: none;
        height: 80vh;

        position: relative;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .item {
          width: 10rem;
          height: 10rem;

          // background-color: #eee;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          position: absolute;
          top: calc(50% - 5rem);
          left: calc(50% - 5rem);

          z-index: 1;
          transition: 0.35s ease-in-out;
          cursor: pointer;

          @for $i from 1 through 6 {

            &.i#{$i}.active,
            &.i#{$i}:hover {
              .image {
                &::before {
                  opacity: 1 !important;
                }

                &::after {
                  background-image: url("~@/assets/images/features/feature-#{$i}-white.svg");
                  opacity: 1 !important;
                  transform: scale(1.2);
                }
              }

              .text {
                font-weight: 600;
                color: $black;
              }
            }
          }

          @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
              .image {
                background-image: url("~@/assets/images/features/feature-#{$i}.svg");

                &::before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(45deg, #ff852d, #ff5100);
                  border-radius: 50%;

                  position: absolute;
                  top: 0;
                  left: 0;

                  z-index: 1;
                  opacity: 0;
                  transition: 0.35s ease-in-out;
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  background-image: url("~@/assets/images/features/feature-#{$i}.svg");
                  background-size: contain;
                  background-position: center;
                  background-origin: content-box;
                  background-repeat: no-repeat;
                  box-sizing: border-box;
                  // border-radius: 50%;

                  position: absolute;
                  top: 0;
                  left: 0;

                  z-index: 1;
                  opacity: 0;
                  padding: 0.9rem;
                  transition: 0.5s ease-in-out;
                }
              }
            }
          }

          &.i1 {
            transform: rotateZ(-50deg) translateY(-20rem) rotateZ(50deg);
          }

          &.i2 {
            transform: rotateZ(50deg) translateY(-20rem) rotateZ(-50deg);
          }

          &.i3 {
            transform: rotateZ(-90deg) translateY(-20rem) rotateZ(90deg);
          }

          &.i4 {
            transform: rotateZ(90deg) translateY(-20rem) rotateZ(-90deg);
          }

          &.i5 {
            transform: rotateZ(-130deg) translateY(-20rem) rotateZ(130deg);
          }

          &.i6 {
            transform: rotateZ(130deg) translateY(-20rem) rotateZ(-130deg);
          }

          .image {
            width: 3.5rem;
            height: 3.5rem;

            border-radius: 50%;
            border: 1px solid $orange;
            box-shadow: 0 10px 30px rgba($color: #ff5100, $alpha: 0.15);
            background-color: #fff;

            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;

            padding: 0.9rem;
            margin-bottom: 1rem;
            transition: 0.35s ease-in-out;
            position: relative;
            overflow: hidden;
          }

          .text {
            text-align: center;
            color: #6f6f6f;

            transition: 0.35s ease-in-out;
          }
        }

        .circle-ctn {
          width: 30rem;
          height: 30rem;

          // background-color: pink;

          .circle {
            border-radius: 50%;
            border: 1px solid #c6c6c6;
            position: absolute;

            --circle-spacing: 3.5rem;
            --circle-initial-size: 27rem;

            @for $i from 1 through 4 {
              &.c#{$i} {
                width: calc(var(--circle-initial-size) + ((#{$i} - 1) * 2 * var(--circle-spacing)));
                height: calc(var(--circle-initial-size) + ((#{$i} - 1) * 2 * var(--circle-spacing)));

                top: calc(50% - ((var(--circle-initial-size) + ((#{$i} - 1) * 2 * var(--circle-spacing))) / 2));
                left: calc(50% - ((var(--circle-initial-size) + ((#{$i} - 1) * 2 * var(--circle-spacing)))) / 2);
                opacity: calc(1 - ((#{$i} - 1) * 0.25));
              }
            }
          }
        }

        >.image {
          height: 100%;
          width: 19rem;

          // background-color: #eee;
          background-size: contain;
          background-position: center;
          background-origin: content-box;
          background-repeat: no-repeat;

          position: absolute;
          top: 0;
          left: calc(50% - 9.5rem);

          transform: scale(1.1);
          transition: 0.35s ease-in-out;
          padding: 2rem;

          @for $i from 1 through 6 {
            &.i#{$i} {
              background-image: url("~@/assets/images/features/feature-phone-#{$i}.png");
            }
          }
        }
      }
    }
  }

  .banking {
    .ctn {
      width: var(--max-content-width);
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;

      position: relative;

      .canvas {
        .decor-12 {
          display: none;
          width: 50vw;
          height: 100vh;

          // background-color: black;
          background-size: contain;
          background-position: center right;
          background-origin: content-box;
          background-repeat: no-repeat;
          background-image: url("~@/assets/images/decors/decor-12.png");
          image-rendering: -webkit-optimize-contrast;

          position: absolute;
          top: -2rem;
          right: calc((100vw - var(--max-content-width)) / -2);

          // transform: translateX(10%);

          @media screen and (min-width: 768px) {
            display: block;
          }
        }
      }

      .content {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 768px) {
          width: 50%;
          min-height: 90vh;
        }

        &.reverse {
          align-self: flex-end;
        }

        .title {
          line-height: 1.5rem;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;

          margin-bottom: 2rem;
          margin-top: 3rem;

          @media screen and (min-width: 768px) {
            width: 100%;

            text-align: left;
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 2.5rem;
            margin-top: 0;
            margin-bottom: 1rem;
          }
        }

        .description {
          font-weight: 400;
          letter-spacing: 0.01em;
          line-height: 1.5rem;
          text-align: center;
          color: #292d32;

          margin-bottom: 1.5rem;

          @media screen and (min-width: 768px) {
            text-align: left;
          }
        }

        .item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          margin-bottom: 1rem;

          @media screen and (min-width: 768px) {
            width: 80%;
            align-self: start;
          }

          .bullet {
            width: 2.5rem;
            height: 2.5rem;

            background: linear-gradient(45deg, #ff852d, #ff5100);
            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;
            border-radius: 50%;
            box-shadow: 0 10px 30px rgba($color: $orange, $alpha: 0.15);

            color: #fff;
            line-height: 2.5rem;
            text-align: center;

            margin-right: 1.5rem;
            position: relative;

          }

          .text {
            flex-grow: 1;
            width: calc(100% - 4rem);
            color: #292d32;
            letter-spacing: 0.01em;
            font-size: 0.9rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .team {
    .canvas {
      .decor-7 {
        display: none;
        width: 10rem;
        height: 10rem;

        // background-color: pink;
        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-7.svg");

        position: absolute;
        top: 3rem;
        right: -5rem;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }

      .decor-8 {
        display: none;
        width: 10rem;
        height: 10rem;

        // background-color: pink;
        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-7.svg");

        position: absolute;
        bottom: 8rem;
        left: -5rem;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }
    }

    .content-ctn {
      // width: 100vw;

      // overflow-x: scroll;

      .content {
        width: 100vw;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;

        padding: 2rem calc((100vw - var(--max-content-width)) / 2);
        overflow-x: scroll;

        @media screen and (min-width: 768px) {
          display: grid;
          gap: 2rem;
          grid-template-columns: repeat(3, minmax(auto, 16rem));
          justify-content: center;
        }

        .item {
          width: 60vw;
          border-radius: 1rem;
          box-shadow: 0 0 30px rgba($color: #4d4d4d, $alpha: 0.08);

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          padding: 2rem;
          transition: 0.35s ease-in-out;

          @media screen and (min-width: 768px) {
            width: 100%;
            height: 100%;

            // width: calc((var(--max-content-width) - 4rem) / 3);

            // box-shadow: unset;
            // border-radius: unset;
            // max-width: 16rem;
          }

          &:hover {
            border-radius: 1rem;
            box-shadow: 0 0 30px rgba($color: #4d4d4d, $alpha: 0.08);
          }

          &+.item {
            margin-left: 2rem;

            @media screen and (min-width: 768px) {
              margin-left: 0;
            }
          }

          @for $i from 1 through 2 {
            &:nth-child(#{$i}) {
              .image {
                background-image: url("~@/assets/images/teams/team-#{$i}.png");
              }
            }
          }

          &:nth-child(3) {
            .image {
              background-image: url("~@/assets/images/teams/team-4.jpg");
            }
          }

          &:nth-child(4) {
            .image {
              background-image: url("~@/assets/images/teams/team-5.jpeg");
            }
          }

          &:nth-child(5) {
            .image {
              background-image: url("~@/assets/images/teams/team-6.jpg");
            }
          }

          &:nth-child(6) {
            .image {
              background-image: url("~@/assets/images/teams/team-3.png");
            }
          }

          .image {
            width: 10rem;
            height: 10rem;

            border-radius: 50%;
            background-color: #eee;

            background-size: cover;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;

            margin-bottom: 2rem;
          }

          .name {
            font-weight: 600;
            letter-spacing: 0.01em;
            line-height: 2rem;
            font-size: 1.25rem;
            text-align: center;
          }

          .position {
            font-size: 1rem;
            line-height: 1.5rem;
            text-align: center;
            letter-spacing: 0.02em;
            color: $dark-blue;

            margin-bottom: 0.75rem;
          }

          .linkedin {
            width: 2rem;
            height: 2rem;

            background-color: #f3f3f3;
            border-radius: 50%;
            background-size: contain;
            background-position: center;
            background-origin: content-box;
            background-repeat: no-repeat;
            background-image: url("~@/assets/images/socials/linkedin.svg");

            padding: 0.5rem;
            transition: 0.35s ease-in-out;
            cursor: pointer;

            &:hover,
            &:focus {
              background-color: #f5f8ff;
              background-image: url("~@/assets/images/socials/linkedin-hover.svg");
            }
          }
        }
      }
    }
  }

  .download {
    padding: 3rem 0;

    .canvas {
      background-color: #f4fcff;

      .decor-10 {
        width: 7rem;
        height: 7rem;

        background-size: contain;
        background-position: top right;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-10.svg");

        position: absolute;
        top: 0;
        right: 0;

        @media screen and (min-width: 768px) {
          width: 10rem;
          height: 10rem;
        }
      }

      .decor-11 {
        width: 7rem;
        height: 7rem;

        background-size: contain;
        background-position: bottom left;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/decors/decor-11.svg");

        position: absolute;
        bottom: 0;
        left: 0;

        @media screen and (min-width: 768px) {
          width: 10rem;
          height: 10rem;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .store-bar {
      display: flex;
      flex-direction: row;

      .item {
        height: 3rem;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-color: unset;

        position: relative;

        cursor: pointer;
        // margin-right: 2rem;

        &+.item {
          margin-left: 2rem;
        }

        &::before {
          content: "";
          width: 90%;
          height: 90%;

          position: absolute;
          top: 5%;
          left: 5%;

          box-shadow: 0 4px 10px rgba($color: #000000, $alpha: 0.25);
        }

        &.ios {
          width: 8.5rem;
          background-image: url("~@/assets/images/app-store.svg");
        }

        &.and {
          width: 10rem;
          background-image: url("~@/assets/images/play-store.svg");
        }
      }
    }
  }
}
</style>
