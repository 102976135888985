import { createRouter, createWebHistory } from 'vue-router'
import RedirectHome from '@/pages/redirect/RedirectHome.vue'
import HomePage from '@/pages/home/HomePage.vue'
import MainLayout from '@/layouts/MainLayout.vue'

import pollRouter from "./poll"

const routes = [
  {
    path: '/',
    name: 'main-layout',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home-page',
        component: HomePage,
      },
    ]
  },
  ...pollRouter,
  {
    path: '/:pathMatch(.*)*',
    name: 'redirect-home',
    component: RedirectHome,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
