<template>
  <div :class="`nav-bar-component ${isNavBarBackgroundActive ? 'active' : ''}`">
    <div class="content">
      <div class="menu-btn" @click="toggleMenu(true)"></div>
      <div class="logo" @click="scrollTo('top')"></div>
      <div :class="`backdrop ${isMenuActive ? 'active' : ''}`" @click="toggleMenu(false)"></div>
      <div :class="`menu ${isMenuActive ? 'active' : ''}`">
        <div class="close-btn" @click="toggleMenu(false)"></div>
        <div class="logo" @click="scrollTo('top')"></div>
        <div :class="`item ${focusedContent == 'service-section' ? 'active' : ''}`" @click="scrollTo('service-section')">
          Our Services
        </div>
        <div :class="`item ${focusedContent == 'feature-section' ? 'active' : ''}`" @click="scrollTo('feature-section')">
          Key Features
        </div>
        <div :class="`item ${focusedContent == 'team-section' ? 'active' : ''}`" @click="scrollTo('team-section')">
          Team
        </div>
        <div :class="`item ${focusedContent == 'join-now-section' ? 'active' : ''
          }`" @click="openRegistrationModal">
          Join Now
        </div>
        <div class="bottom-bar">
          <p class="copyright">Copyright © 2022 <span>FAM, Inc.</span></p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { signInAnonymously } from "firebase/auth";
import { auth, db } from "@/services/firebase";

export default {
  name: "NavBarComponent",
  computed: {
    isMenuActive() {
      return this.$store.state.appStore.isMenuActive;
    },
    isNavBarBackgroundActive() {
      return this.scrollY > 50;
    },
    scrollOffset() {
      return 100;
    },
  },
  data() {
    return {
      scrollY: 0,
      focusedContent: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.scrollY = document.querySelector("body").scrollHeight;
  },
  methods: {
    toggleMenu(param) {
      this.$store.dispatch("appStore/setIsMenuActive", param);
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (
        window.innerHeight + window.scrollY + this.scrollOffset >=
        document.body.offsetHeight
      ) {
        this.focusedContent = "join-now-section";
      } else if (
        document.getElementById("team-section").getBoundingClientRect().top -
        this.scrollOffset <
        0
      ) {
        this.focusedContent = "team-section";
      } else if (
        document.getElementById("feature-section").getBoundingClientRect().top -
        this.scrollOffset <
        0
      ) {
        this.focusedContent = "feature-section";
      } else if (
        document.getElementById("service-section").getBoundingClientRect().top -
        this.scrollOffset <
        0
      ) {
        this.focusedContent = "service-section";
      } else {
        this.focusedContent = "";
      }
    },
    scrollTo(param) {
      if (param == "top") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.toggleMenu(false);

        return;
      }

      var element = document.getElementById(param);
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition =
        window.pageYOffset + elementPosition - this.scrollOffset + 2;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      this.toggleMenu(false);
    },
    openRegistrationModal() {
      this.$store.dispatch("appStore/setIsRegistrationModalActive", true);
      this.toggleMenu(false);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/variables.scss";

.nav-bar-component {
  width: 100vw;
  height: var(--nav-bar-height);

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  box-shadow: 0 2px 10px rgba($color: #111111, $alpha: 0.05);
  z-index: 40;
  // transform-style: preserve-3d;

  @media screen and (min-width: 768px) {
    box-shadow: unset;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;

    background-color: #fff;
    box-shadow: 0 2px 10px rgba($color: #111111, $alpha: 0.05);

    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: 0.2s;

    @media screen and (min-width: 768px) {
      opacity: 0;
    }
  }

  &.active {
    &::before {
      opacity: 1;
    }
  }

  .backdrop {
    width: 100vw;
    height: 100vh;

    background: rgba($color: #000000, $alpha: 0.3);

    position: fixed;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;
    transition: 0.35s ease-in-out;

    &.active {
      z-index: 10;
      opacity: 1;
      visibility: visible;
    }
  }

  .content {
    width: 100%;
    max-width: var(--max-content-width);
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
      justify-content: start;
    }

    .menu-btn {
      width: 3rem;
      height: 3rem;

      // background-color: pink;
      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/icons/menu-blue.svg");

      position: absolute;
      top: 0.5rem;
      left: 0.5rem;

      padding: 0.25rem;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .logo {
      width: 10rem;
      height: 100%;

      background-size: contain;
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-image: url("~@/assets/images/logo.png");

      padding: 0.75rem;
      cursor: pointer;

      z-index: 5;

      @media screen and (min-width: 768px) {
        background-position: left center;

        padding: 1.5rem;
        padding-left: 0;
      }
    }

    .menu {
      width: var(--menu-width);
      height: 100vh;

      background-color: #fff;

      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;

      position: fixed;
      top: 0;
      left: calc(var(--menu-width) * -1);

      padding: 1.5rem;
      padding-top: 4rem;

      z-index: 50;
      transition: 0.35s ease-in-out;

      @media screen and (min-width: 768px) {
        width: unset;
        height: unset;
        background-color: unset;

        flex-direction: row;
        align-items: center;

        position: relative;
        left: 0;
        padding-top: 0;
        padding: 0;
        transform: translateZ(2px);
      }

      &.active {
        transform: translateX(var(--menu-width));
      }

      .close-btn {
        width: 2rem;
        height: 2rem;

        // background-color: red;

        background-size: contain;
        background-position: center;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/icons/cross-black.svg");

        transition: 0.35s ease-in-out;
        cursor: pointer;

        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        &:hover {
          filter: brightness(150%);
        }

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .logo {
        width: 8rem;
        height: 5rem;

        background-size: contain;
        background-position: center left;
        background-origin: content-box;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/logo.png");

        padding: 0.75rem;
        padding-left: 0;
        cursor: pointer;

        margin-bottom: 1rem;

        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .item {
        line-height: 3rem;
        font-weight: 600;
        letter-spacing: 0.02em;
        color: #b7b7b7;

        position: relative;
        cursor: pointer;
        transition: 0.35s ease-in-out;

        @media screen and (min-width: 768px) {
          font-weight: 400;
        }

        &.active {
          color: #111111;

          &::before {
            max-width: 100%;
          }
        }

        &:hover {
          font-weight: 500;
          color: #111111;

          &::before {
            max-width: 100%;
          }
        }

        &::before {
          content: "";
          width: 100%;
          max-width: 0;
          height: 2px;

          background-color: $dark-blue;
          border-radius: 1px;

          position: absolute;
          bottom: 0.25rem;
          left: 0;

          transition: 0.35s ease-in-out;
        }

        &+.item {
          @media screen and (min-width: 768px) {
            margin-left: 2.5rem;
          }
        }
      }
    }

    .bottom-bar {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      position: absolute;
      bottom: 0;
      left: 0;

      @media screen and (min-width: 768px) {
        display: none;
      }

      .copyright {
        text-align: center;
        font-weight: 400;
        line-height: 5rem;
        color: #707070;

        span {
          font-weight: 400;
          color: $dark-blue;
        }
      }
    }
  }
}
</style>
