import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";


export const firebaseApp = initializeApp({
  apiKey: "AIzaSyC3e3KRNbyE25p__LOvqOSJCM-FDE7lsU8",
  authDomain: "fammobile-ee4e6.firebaseapp.com",
  databaseURL: "https://fammobile-ee4e6-default-rtdb.firebaseio.com",
  projectId: "fammobile-ee4e6",
  storageBucket: "fammobile-ee4e6.appspot.com",
  messagingSenderId: "626103613915",
  appId: "1:626103613915:web:32675ab45199c7a3498ffe",
  measurementId: "G-EF0ZK544PK"
});

export const db = getFirestore(firebaseApp)

export const auth = getAuth();
