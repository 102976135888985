<template>
  <div class="redirect-home"></div>
</template>


<script>

export default {
  name: 'RedirectHome',
  mounted(){
    window.location = "/";
  }
};
</script>
<style scoped>

</style>
